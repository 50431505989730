import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

export default function ResumeViewer() {
    const theme = useTheme();

    return (
        <Grid 
            container 
            justifyContent="center" 
            alignItems="center" 
            sx={{ width: '100vw', height: '100vh', p: 0, m: 0 }}
        >
            <Grid item xs={12} sx={{ width: '100%', height: '100%' }}>
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 0, // Full-screen view
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    {/* Download Button (Fixed at the top-right) */}
                    <Box 
                        sx={{ 
                            textAlign: 'right', 
                            p: 2, 
                            position: 'absolute', 
                            top: 0, 
                            right: 0, 
                            zIndex: 2 
                        }}
                    >
                        {/* <Button
                            variant="contained"
                            color="success"
                            onClick={() => window.open('/resume.pdf', '_blank')}
                            sx={{
                                px: 2,
                                py: 1,
                                fontSize: isMobile ? '0.8rem' : '1rem',
                                borderRadius: 2,
                            }}
                        >
                            Download PDF
                        </Button> */}
                    </Box>

                    {/* Full Width PDF View */}
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <iframe
                            src="/GauravAvulaResume.pdf"
                            title="Resume"
                            width="100%"
                            height="100%"
                            style={{
                                border: 'none',
                                display: 'block',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                        />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
}
